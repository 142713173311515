import {
  COMPANY_USER_ROLE_FOR_COMMUNITY_USER_FRAGMENT,
} from '@/graphql/_Fragments/CompanyUserRole/ForCommunityUser';

export const COMMUNITY_USER_COMPANY_USER_ROLE_FRAGMENT = `
  fragment communityUserCompanyUserRoleFragment on CommunityUser {
    uid
    schemaCode
    firstName
    lastName
    name
    email
    jobTitle
    employerName
    profilePrivacyMode
    pictureFileResource {
      ...fileResourceBaseFragment
    }
    companyRoles {
      ...companyUserRoleForCommunityUserFragment
    }
  }
  ${COMPANY_USER_ROLE_FOR_COMMUNITY_USER_FRAGMENT}
`;
